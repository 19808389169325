













import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import DisplayNameForm from '@/components/dashboard/DisplayNameForm.vue';
import SummaryForm from '@/components/dashboard/SummaryForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    DisplayNameForm,
    SummaryForm,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
});
