

































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    business: {
      type: Object as () => ApiResource.Business,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      form: this.business.summary,
      loading: false,
      validation: null,
    };
  },
  methods: {
    async save() {
      this.error = null;
      this.loading = true;

      await this.$store.dispatch('business/UpdateProfile', {
        BUSINESS_ID: this.business.id,
        form: {
          summary: this.form,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
